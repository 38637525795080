// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-cn-js": () => import("./../../../src/pages/404.cn.js" /* webpackChunkName: "component---src-pages-404-cn-js" */),
  "component---src-pages-404-en-js": () => import("./../../../src/pages/404.en.js" /* webpackChunkName: "component---src-pages-404-en-js" */),
  "component---src-pages-about-cn-js": () => import("./../../../src/pages/about.cn.js" /* webpackChunkName: "component---src-pages-about-cn-js" */),
  "component---src-pages-about-en-js": () => import("./../../../src/pages/about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-connect-cn-js": () => import("./../../../src/pages/connect.cn.js" /* webpackChunkName: "component---src-pages-connect-cn-js" */),
  "component---src-pages-connect-en-js": () => import("./../../../src/pages/connect.en.js" /* webpackChunkName: "component---src-pages-connect-en-js" */),
  "component---src-pages-faq-cn-js": () => import("./../../../src/pages/faq.cn.js" /* webpackChunkName: "component---src-pages-faq-cn-js" */),
  "component---src-pages-faq-en-js": () => import("./../../../src/pages/faq.en.js" /* webpackChunkName: "component---src-pages-faq-en-js" */),
  "component---src-pages-form-cn-js": () => import("./../../../src/pages/form.cn.js" /* webpackChunkName: "component---src-pages-form-cn-js" */),
  "component---src-pages-form-en-js": () => import("./../../../src/pages/form.en.js" /* webpackChunkName: "component---src-pages-form-en-js" */),
  "component---src-pages-index-cn-js": () => import("./../../../src/pages/index.cn.js" /* webpackChunkName: "component---src-pages-index-cn-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-information-cn-js": () => import("./../../../src/pages/information.cn.js" /* webpackChunkName: "component---src-pages-information-cn-js" */),
  "component---src-pages-information-en-js": () => import("./../../../src/pages/information.en.js" /* webpackChunkName: "component---src-pages-information-en-js" */),
  "component---src-pages-information-topic-cn-js": () => import("./../../../src/pages/information/topic.cn.js" /* webpackChunkName: "component---src-pages-information-topic-cn-js" */),
  "component---src-pages-information-topic-en-js": () => import("./../../../src/pages/information/topic.en.js" /* webpackChunkName: "component---src-pages-information-topic-en-js" */)
}

